<template>
  <b-modal id="Messagebox" ref="Messagebox" hide-footer>
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <div class="pop-up-title">Course Information</div>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>
    <div>

    </div>
    <template>
      <div class="col-md-12 col-lg-12 col-sm-12" style="padding: 0px">
        <div>
          <div style="padding: 0 10 10 10; display: flex">
            <div class="col-md-4 col-lg-4 col-sm-4" style="padding-right: 0px; padding-left: 0px">
              <div class="col-md-12" style="padding:0">
                <label class="img-label">{{ dataObj.shortName }}</label>
                <img alt="..." src="./../../assets/background_education.png" class="card-img-top cource-img" />
              </div>
            </div>
            <div class="col-md-8 col-lg-8 col-sm-8" style="padding-left: 0px">
              <div class="title-class" style="padding-left: 10px">
                {{ dataObj.title }}
              </div>
              <div class="" style="padding-left: 10px; display: flex" v-if="dataObj.enrolled">
                <div>
                  <img alt="mul_users" src="../../assets/mul_users.svg" style="opacity: 0.5" />
                </div>
                <div class="enrol-class" style="margin-left: 5px">
                  {{ dataObj.enrolled }} enrollments
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="p-class">
            {{ dataObj.description }}
          </p>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "Messagebox",
  props: {
    dataObj: Object,
  },
  data() {
    return {
      request: "",
      shows: false,
      source: "",
    };
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.img-label {
  color: #3562a2;
  font-size: 3.2rem;
  font-family: "BarlowM", sans-serif;
  position: absolute;
  padding: 10% 0;
  text-align: center;
  width: 100%;
}

.header-class {
  display: flex;
}

.x-class {
  text-align: right;
  margin-right: 10px;
}

.p-class {
  color: #313131;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
  line-height: 1.9166666666666667rem;
  text-align: justify;
  margin-top: 2rem;
}

.enrol-class {
  color: #313131;
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
  line-height: 1.6666666666666667rem;
  opacity: 0.5;
}

.title-class {
  padding: 10px;
  color: #313131;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.25rem;
}

.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  line-height: 0.8333333333333334rem;
  font-family: "BarlowSb", sans-serif;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
  text-align: right;
}

.edit-btn {
  height: 2.2rem;
  padding-top: 0.25rem !important;
  margin-left: 0.5rem;
}

.edit-btn:focus {
  background-color: #274ba7;
}
</style>