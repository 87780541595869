<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <div>
      <Breadcrumb />
    </div>
    <div class="main-con">
      <div class="top-tab-block">
        <div style="display: flex">
          <div class="col-md-8 col-lg-8 col-sm-8" style="padding: 0">
            <div style="display: flex">
              <span class="card-title card-title-cl card-main-tl search-course-tl" style="color: #234372">{{
                searchCourse()
              }}
              </span>
            </div>
            <div style="display: flex">
              <div class="text-ad">
                {{ selCourse() }}
              </div>
              <div class="course-selected" v-if="assignCourseNum.length">
                &nbsp; {{ courseNumberFunc() }}
              </div>
            </div>
          </div>
          <div class="filter-and-search">

            <b-input class="search-box-input" v-model="search" placeholder="Search Course">
            </b-input>
            <span>

              <b-icon v-if="search == null || search == ''" icon="search" class="icon-class" aria-hidden="true">
              </b-icon>
              <b-icon v-else @click="cancelSearch()" icon="X" class="icon-class" aria-hidden="true"></b-icon>
            </span>

          </div>
        </div>
      </div>
      <div class="border-bottom assign-course scroll">

        <div class="row" style="margin: 0">

          <div class="box-st" v-for="i in filteredList" :key="i.itemID" :id="i.itemID">
            <div style="padding: 1rem">
              <div @click="courseSelect(i.itemID)">

                <div class="col-md-12" style="padding: 0">

                  <label class="img-label">{{
                    i.itemID.split("_").pop()
                  }}</label>

                  <img alt="..." src="./../../assets/background_education.png" style="width: 100%" />
                </div>
                <div class="col-md-12 col-lg-12 col-sm-12" style="padding-left: 0px; padding-top: 10px">
                  <span class="title-class">
                    {{ titleDescriptionT(i.itemTitle) }}
                  </span>
                </div>
              </div>

              <div class="checkbox-detail-part">

                <div style="padding-left: 0px">
                  <label class="sel-check"><input class="input-check" v-model="checkedCategories" type="checkbox"
                      :value="i.itemID" @change="check(i.itemID)" /><span
                      v-if="checkedCategories.indexOf(i.itemID) !== -1">Selected</span>
                    <span v-else>Select</span>
                  </label>
                </div>

                <div class="ft-detail">
                  <b-button v-b-modal.Messagebox class="primar-btn discard-btn ft-detail"
                    style="padding-top: 0px; padding-right: 0px" size="lg"
                    @click="detail_id = i.itemID; messageboxProp();">
                    Details
                    <b-icon class="detial-icon ft-detail-icon" icon="chevron-right"></b-icon>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="filteredList.length == 0 && showLoader == false" class="no-data" style="margin-top: 5rem">
          <img alt="no data" src="../../assets/No_Data.svg" />
          <span class="ed-date">No Records Found</span>
        </div>
      </div>
      <div class="add-action-footer-info" v-if="filteredList.length > 0">

        <b-button class="discard-btn1" size="lg" @click="resetbtnFunc()">
          Reset
        </b-button>
        <b-button pill class="primar-btn submit-btn" size="lg" @click="assignLearningtoSelf()">
          Assign To Self
        </b-button>

      </div>
      <Messagebox class="alert" :dataObj="popUpObj" />
    </div>
  </div>
</template>
<script>
import Loader from "../../components/common/Loader.vue";
import ActionItemFilter from "../../components/Dashboard/ActionItemFilter.vue";
import "../../common/css/CommonStyle.css";
import "../../common/css/font-categories.css";
import EducationFilter from "./EducationFilter.vue";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Messagebox from "./messagebox.vue";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
export default {
  name: "AssignLearning",
  data() {
    return {
      showLoader: false,
      showPopup: false,
      selected: false,
      detail_id: "",
      checkedCategories: [],
      selectedCourse: 0,
      search: "",
      assignCourseNum: [],
      assignLearning: "Assign",
      // popUpObj: {
      //   imgScr:
      //     "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
      //   title: "People and Environment Care",
      //   description:
      //     "Assessment is a necessary part of the teaching and learning process, helping us measure whether our students have really learned what we want them to learn. While exams and quizzes are certainly favorite and useful methods of assessment, out of class  assignments (written or otherwise) can offer similar insights into our students’ learning. And just as creating a reliable test takes thoughtfulness and skill, so does creating meaningful and effective assignments.",
      //   enrolled: 2222,
      // },
      popUpObj: {
        id: "",
        imgScr: "",
        title: "",
        description: "",
        enrolled: "",
      },
      noOfFilters: 4,
      filterArray: [],
      filterSelected: [],
    };
  },
  components: {
    Breadcrumb,
    Messagebox,
    ActionItemFilter,
    EducationFilter,
    Loader,
  },
  created() {
    this.$store.dispatch("breadcrumb", {
      title: "Education",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Education", primary: false },
      ],
    });
    this.filterArray = [
      {
        labelName: "Category",
        arrayValue: ["XYZ", "ABC"],
        id: "Category",
      },
    ];
    this.assignedCourses();
  },
  computed: {
    filteredList() {
      return this.assignCourseNum.filter((post) => {
        return post.itemTitle.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    selCourse() {

      if (this.assignCourseNum.length === 1)
        return "Please select the course from below.";
      else if (this.assignCourseNum.length === 0) return "";
      else return "Please select the courses from below.";
    },
    searchCourse() {

      if (this.assignCourseNum.length < 2) return "Search PO Course";
      else return "Search PO Courses";
    },

    courseNumberFunc() {
      if (this.checkedCategories.length < 2) {
        return this.checkedCategories.length + " Course Selected.";
      } else {
        return this.checkedCategories.length + " Courses Selected.";
      }
    },
    applyFilter() { },
    resetFilter() { },
    changedFilter() { },
    onSelect(option, index) { },
    onRemove(option, index) { },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    titleDescriptionT(actionDesc) {

      return actionDesc.length < 39
        ? actionDesc
        : actionDesc.substr(0, 39) + "...";
    },
    assignLearningtoSelf() {
      this.showLoader = true;
      let assignLearningArr = [];
      let arr = [];
      for (const element of this.assignCourseNum) {
        for (let el of this.checkedCategories) {
          if (element.itemID === el) {
            assignLearningArr.push(element);
          }
        }
      }
      assignLearningArr.forEach(function (element) {
        let obj = {
          componentTypeID: element.itemTypeID,
          componentID: element.itemID,
          revisionDate: element.revisionDate,
        };
        arr.push(obj);
      });
      let requestPayload = arr;

      ApiCalls.httpCallDetails(
        Api.ADDCOURSEANDLEARNINGTOSELF,
        "post",
        requestPayload
      ).then((data) => {
        this.showLoader = false;
        this.$router.push("/EducationHomePage");
      });
    },
    cancelSearch() {
      this.search = "";
    },
    check(id) {
      if (this.checkedCategories.indexOf(id) !== -1) {
        document.getElementById(id).classList.add("selected-course");
      } else {
        document.getElementById(id).classList.remove("selected-course");
      }
    },
    resetbtnFunc() {
      let id;
      for (const element of this.checkedCategories) {
        id = element;
        document.getElementById(id).classList.remove("selected-course");
      }
      this.checkedCategories = [];
    },

    messageboxProp() {
      this.showPopup = false;
      let detailPopUp = [];
      let ids = "";
      let popUp = {
        id: "",
        imgScr: "",
        title: "",
        description: "",
        enrolled: "",
        shortName: '',
      };
      for (const element of this.assignCourseNum) {

        if (this.detail_id == element.itemID) {
          popUp.id = element.itemID;
          popUp.imgScr = element.imageUrl;
          popUp.title = element.itemTitle;
          popUp.description = element.description;
          popUp.shortName = element.shortName;
          popUp.enrolled = "";
        }

        detailPopUp.push(popUp);
      }
      for (const element of detailPopUp) {
        ids = element.id;
        if (ids == this.detail_id) {
          this.popUpObj = element;
        }
      }
      this.showPopup = true;
    },
    courseSelect(id) {
      if (this.checkedCategories.indexOf(id) !== -1) {
        this.checkedCategories.splice(this.checkedCategories.indexOf(id), 1);
        document.getElementById(id).classList.remove("selected-course");
        console.log("this.checkedCategories2 ", this.checkedCategories);
        this.selected = false;
      } else {
        this.checkedCategories.push(id);
        document.getElementById(id).classList.add("selected-course");
        console.log("this.checkedCategories3 ", this.checkedCategories);
        this.selected = true;
      }
    },
    assignedCourses() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.SEARCHALLTRAININGANDCOURSES, "get").then(
        (data) => {
          if (data.success) {
            this.assignCourseNum = data.data.result;
            this.showLoader = false;
            this.assignCourseNum.forEach((element) => {
              element.shortName = "" + element.itemID.split("_").pop() + "";
              element.itemTitle =
                element.itemTitle +
                " (" +
                element.itemID.split("_").pop() +
                ")";
            });
          }
        }
      );
    },
  },
};
</script>
<style scoped>
.img-label {
  color: #3562a2;
  font-size: 3rem;
  font-family: "BarlowM", sans-serif;
  position: absolute;
  padding: 12% 0;
  text-align: center;
  width: 100%;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.text-ad {
  color: #313131;
  font-family: "BarlowR", sans-serif;
  font-size: 1.1666666666666667rem;
  line-height: 1.75rem;
  padding: 0px;
  /* padding-left: px; */
}

.search-fun-box {
  text-align: right;
  display: flex;
}

.icon-class {
  /* margin-top: 10px; */
  height: 20px;
  width: 18px;
  margin-left: -3rem;
  /* padding-right: 30px; */
  padding-top: 2px;
  color: grey;
  font-size: 16px;
}

/* by Chetan */

.top-tab-block {
  padding: 2rem 2rem 0;
}

.tab-name-block {
  display: flex;
  padding-left: 10px;
}

.filter-and-search {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  height: 45px;
  width: 100%;
  align-items: center;
}

.search-box-input {
  height: 40px;
  border: 1px solid #313131;
  font-family: "BarlowR", sans-serif;
  line-height: 1.25rem;
  width: 27.5;
}

.edit-learning-block {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.checkbox-detail-part {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 1rem 0;
}

/* by Chetan */
.search-course-tl {
  color: #313131;
  font-family: "BarlowM", sans-serif;
  font-size: 1.6666666666666667rem;
  line-height: 1.75rem;
  padding-left: 15px;
}

.scroll {
  overflow: auto;
  height: 450px;
}

.scroll::-webkit-scrollbar {
  width: 9px;
  height: 84px;
}

.scroll::-webkit-scrollbar-thumb:window-inactive {
  background: #274ba7;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #274ba7;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.add-action-footer-info {
  padding-left: 2em !important;
  padding-right: 2em !important;
  padding-top: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.selected-course {
  background: #c6deff;
}

.search-box {
  display: flex;
  text-align: right;
  padding-top: 25px;
  height: 30px;
}



.acti-item-filter {
  /* width: 68%; */
  display: flex;
  margin-top: -5px;
  flex-direction: row-reverse;
  margin-left: 10%;
  position: absolute;
  right: 10px;
  top: 35px !important;
}

.funnel-con {
  font-size: 16px;
  height: 32px;
  width: 32px;
}

.discard-btn1 {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn {
  /* color: #274ba7; */
  background: transparent;
  border: transparent;
  /* font-family: "BarlowM";
  font-size: 1.166rem;
  margin-right: 2rem; */
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.assign-course {
  /* padding-top: 20px; */
  /* padding-left: 0px !important; */
  flex-direction: column;
  /* padding-right: 0px !important; */
  padding-top: 1rem;
  margin: 1rem 1rem;
  /* padding-bottom: 20px; */
  overflow: auto;
}

.box-st {
  border: 1px solid #cecbcb;
  border-radius: 5%;
  width: 17.5%;
  /* width:215px; */
  padding: 0;
  margin: 0 1rem 1rem 1rem;
}

.ft-detail {
  color: #274ba7 !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-family: "BarlowM", sans-serif !important;
}

.ft-detail-icon {
  color: #274ba7 !important;
  /* font-size: 1.1666666666666667rem !important; */
  font-family: "BarlowM", sans-serif !important;
}

.title-class {
  color: #000000;
  font-size: 1.1666666666666667rem;
  line-height: 1.5rem;
  font-family: "BarlowM", sans-serif;
  word-break: break-word;
}

.sel-check {
  color: #274ba7;
  font-size: 1rem;
  line-height: 1.5833333333333333rem;
  font-family: "BarlowM", sans-serif;
  display: block;
  margin: 0;
}

.mem-check {
  color: #313131;
  font-size: 1rem;
  line-height: 1.5833333333333333rem;
  font-family: "BarlowM", sans-serif;
  display: block;
}

.input-check {
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  *overflow: hidden;
  margin-right: 4px;
}

.doc-bt {
  border-color: #869ed8 !important;
  /* background: #eff3ff 0% 0% no-repeat padding-box !important; */
  background: transparent;
  color: #274ba7 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.doc-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.view-class {
  font-size: 1.2rem;
  font-family: "BarlowM", sans-serif;
}

.submit-btn {
  height: 3rem;
  width: 9.9rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  padding: 0px;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.ed-date {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.action-item {
  padding: 0 !important;
  margin-top: 18px;
}

.card-title-cl {

  /* font-size: 0.875em;
  font-weight: 600; */
  color: #234372;
  padding: 0;
}

.action-item-card {
  padding: 0px !important;
}

.edu-card {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 992px) {
  .box-st {
    width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .box-st {
    width: 40%;
  }
}

@media only screen and (max-width: 890px) {
  .box-st {
    width: 20%;
    margin: 0 0.5rem 0.5rem 1rem;
  }
}

@media only screen and (min-width: 990px) and (max-width: 1097px) {
  .box-st {
    width: 20%;
  }
}

@media only screen and (max-width: 576px) {
  .box-st {
    width: 100%;
  }
}

@media only screen and (min-width: 1499px) and (max-width: 2700px) {
  .box-st {
    width: 13%;
    /* margin: 0 1.5rem 1.5rem 1rem; */
  }
}

/* @media only screen and (min-width: 1600px) and (max-width: 2700px) {
  .box-st{
    margin:0 1.5rem 1.5rem 1rem;
  }
} */

.course-selected {
  color: #00790e;
  font-family: "BarlowR", sans-serif;
  font-size: 1.0833333333333333rem;
  line-height: 1.75rem;
  padding: 0px;
}
</style>

